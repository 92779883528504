<template>
  <CFooter :fixed="false">
    <div class="lasDividerbottom"></div>
   <div class="container-fluid rodapeClaimer">
     © 2022 LAS Brasil. Todos os direitos reservados | Site desenvolvido por Yellow Lamp
   </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
.rodapeClaimer {
  margin-top:40px;
  padding-top:15px;
  padding-bottom:15px;
  display:flex;
  align-items:center;
  justify-content: center;
      background-image: linear-gradient(90deg, #206587 50%, #92b9d2 80%) !important;

}
</style>
