export default [{
    _name: 'CSidebarNav',
    _children: [

        {
            _name: 'CSidebarNavTitle',
            _children: []
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Biblioteca Impressa',
            route: '/biblioteca',
            icon: 'cil-browser',
            items: [{
                    name: 'Artigos',
                    to: '/biblioteca/artigos'
                },
                {
                    name: 'Catálogos',
                    to: '/biblioteca/catalogos'
                },
                {
                    name: 'Instruções de Uso',
                    to: '/biblioteca/instrucoes'
                },
                {
                    name: 'Vídeos/Animações',
                    to: '/videos/videosanimacoes'
                },
                {
                    name: 'Webinares/Treinamentos',
                    to: '/videos/webinarestreinamentos'
                },

            ],

        },
       





    ]
}]