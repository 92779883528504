<template>
  <CHeader fixed with-subheader light>
    <div class="container menuHeader">
      <div class="row">
        <div class="col-md-3 menuHeader__logo">
          <img src="@/assets/images/cropped-favicon_las-1.png" />
        </div>
        <div class="col-md-9 menuHeader__menu">
          <div class="menu">
          <ul>
            <li><router-link to="/biblioteca/artigos">Artigos</router-link></li>
            <li><router-link to="/biblioteca/instrucoes-de-uso">Instruções de uso</router-link></li>
            <li><router-link to="/biblioteca/catalogos">Catálogos</router-link></li>
            <li><router-link to="/biblioteca/videos-e-animacoes">Vídeos</router-link></li>
            <li><router-link to="/biblioteca/webinares-e-treinamentos">Webinares</router-link></li>
            
            <li @click="$store.dispatch('logout')">Logout</li>
          </ul>
          </div>

        </div>
      </div>
    </div>
  </CHeader>
</template>

<script>
export default {
  name: "TheHeader",
  components: {},
};
</script>
<style lang="scss" scoped>
.c-header {
  background:none !important;
  border:0px !important;
}

.c-header.c-header-fixed {
  position:relative;
}

.menuHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  .menuHeader__logo {
    img {
      width:120px;
    }
  }
  .menuHeader__menu {
    .menu {
      display: flex;
      justify-content: flex-end;
      align-items:center;
      height:100%;
      ul {
        list-style-type: none;
        padding:0px;
        margin:0px;
        li {
          display:inline-block;
          margin-right:20px;
          font-size:14px;
          padding:5px;
          color:#fff !important;
          a {
            text-decoration:none;
            color:#fff !important;
          }
          cursor:pointer;
          &.logout {
            background-color:#eee;
            border-radius:5px;
          }
          &.site-principal {

          }
        }
      }
    }
  }

}
</style>
